import { useEffect, useState } from "react";
import { getBodySymptomBurdenGraphV1, getBodySymptomBurdenGraphV2, getCompletedTodoListV1, getCompletedTodoListV2, getDataAnalysisGraphsV1, getDataAnalysisGraphsV2, getFoundationSymptomBurdenGraph } from "store/services/clientDataReportsService";
import CustomBarChart from "../../AccordionTabs/NutritionalAssessmentV2/Component/CustomChart";
import ComparisionCustomBarChart from "pages/SideTabs/MyClients/Detail/component/TabContent/DataAnalysis/NAQv2/Component/TabContent/NAQGraph/CustomChart";

const foundationGroups = [
  {
    "Digestion" : [
      "upperGI",
      "liverGallbladder",
      "smallIntestine",
      "largeIntestine",
    ]
  },
  "Blood Sugar Regulation",
  "Stress",
  "Sleep",
  {
    "Nutrient Dense Diet": [
      "mineralNeeds",
      "vitaminNeed",
      "essentialFattyAcids"
    ]
  }
]

export const FoundationSymptomGraphPdf = ({ innerRef, todoId, client, setLoading }) => {
  const [foundationSBGraphData, setFoundationSBGraphData] = useState(null);
  
  useEffect(() => {
    if (!foundationSBGraphData) {
      getFoundationSBGraphData();
    }
  }, []);

  const getFoundationSBGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId: client.id
      };
      setLoading(true);
      const response = await getFoundationSymptomBurdenGraph(data);
      if (response?.success === true && response?.body) {
        // const data = formatFoundations(response.body);
        setFoundationSBGraphData(response?.body);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <>
      <CustomBarChart
        innerRef={innerRef}
        dataset={foundationSBGraphData}
        title="Foundations Symptom Burden Graph"
        totalsTitle="Foundations Totals"
        defaultShowMaxes={true}
        isPdf={true}
      />
    </>
  )
}

export const BodySymptomGraphPdf = ({ innerRef, todoId, client, setLoading }) => {
  const [bodySymptomBurdenGraphData, setBodySymptomBurdenGraphData] = useState(null);
  
  useEffect(() => {
    if (!bodySymptomBurdenGraphData) {
      getBodySymptomBurdenGraphData();
    }
  }, []);

  const getBodySymptomBurdenGraphData = async () => {
    try {
      if (!todoId) {
        throw new Error("Invalid ToDo ID");
      }
      let data = {
        todoId,
        clientId: client.id
      };
      setLoading(true);
      const response = await getBodySymptomBurdenGraphV2(data);
      if (response.success === true) {
        const data = response.body;
        setBodySymptomBurdenGraphData(data);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  return (
    <>
      <CustomBarChart
        innerRef={innerRef}
        dataset={bodySymptomBurdenGraphData}
        title="Body Systems Symptom Burden Graph"
        totalsTitle="Body Systems Totals"
        defaultShowMaxes={true}
        isPdf={true}
      />
    </>
  )
}

export const NAQv2ComparisionGraphPDF = ({ fsbGraphRef, bsGraphRef, selectedNAQs, client, setLoading }) => {
  const [naqList, setNaqList] = useState(null);
  const [ foundationsData, setFoundationsData ] = useState(null);
  const [ bodySystemsData, setBodySystemsData ] = useState(null);

  // useEffect(() => {
  //   if(!naqList) {
  //     getCompletedNAQList();
  //   }
  // }, []);
  
  useEffect(() => {
    if (selectedNAQs && selectedNAQs.length > 1) {
      getNAQv2GraphData();
    }
  }, [selectedNAQs]);

  const getCompletedNAQList = async () => {
    try {
      let data = {
        clientId: client.id
      }
      setLoading(true);
      const response = await getCompletedTodoListV2(data);
      if( response.success === true && response.body.length > 0) {
        return response.body;
      }
      setLoading(false);
    } catch(error) {
      console.log("Error getting graph data", error);
      setLoading(false);
      return null;
    }
  }

  const getNAQv2GraphData = async () => {
    try {
      let data = {
        clientId: client.id,
        values: {
          selectedNAQs
        }
      } 
      setLoading(true);
      const naqList = await getCompletedNAQList();
      const response = await getDataAnalysisGraphsV2(data);
      if( response.success === true &&
        ( response.body?.body_systems?.length && response.body?.foundations?.length )
      ) {
        const { foundations, body_systems: bodySystems } = response.body;
        setFoundationsData(foundations);
        setBodySystemsData(bodySystems);
      }
      setLoading(false);
    } catch(error) {
      console.log("Error getting graph data", error);
      setLoading(false);
    }
  }

  const formatData = (data, naqList) => {
    const foundations = data.foundations.map(foundation => {
      foundation.color = (naqList && naqList.find(naq => naq.id == foundation.todo_id)?.color) || "#2496F1";
      let newData = foundationGroups.map(group => {
        let item = {};
        if(typeof group == "object") {
          for(let key in group) {
            let foundationNames = group[key];
            let highestSymptomChild = null
            let childRows = foundationNames.map(name => {
              let match = foundation.data.find(foundation => foundation.name == name);
              if(!highestSymptomChild || highestSymptomChild.rawTotal < match.rawTotal ) {
                highestSymptomChild = match;
              }
              return match;
            })
            item = {
              ...highestSymptomChild,
              childRows,
              "name": key,
              "abbr": key,
            };
          }
        } else {
          item = foundation.data.find(foundation => foundation.abbr == group);
        }
        return item;
      })

      foundation.data = newData;
      
      return foundation;
    });
    
    const bodySystems = data.body_systems.map(bodySystem => {
      bodySystem.color = (naqList && naqList.find(naq => naq.id == bodySystem.todo_id)?.color) || "#2496F1";
      return bodySystem;
    });
  
    return {
      foundations,
      bodySystems,
    }
  }

  return (
    <>
      <ComparisionCustomBarChart
        innerRef={fsbGraphRef}
        dataset={foundationsData}
        naqList={naqList}
        selectedNAQs={selectedNAQs}
      />
      <ComparisionCustomBarChart
        innerRef={bsGraphRef}
        dataset={bodySystemsData}
        naqList={naqList}
        selectedNAQs={selectedNAQs}
      />
    </>
  )
}